
import { Options, Vue } from 'vue-class-component';

import IconsCardBrand from '@/icons/CardBrand.vue';
import IconNoCreditCard from '@/icons/other/NoCreditCard.vue';
import { PaymentMethod } from '@/interface/payment.interface';

@Options({
  components: {
    IconsCardBrand,
    IconNoCreditCard,
  },
  props: {
    card: Object,
    loader: Boolean,
  },
})
export default class CreditCard extends Vue {
  declare $props: {
    card: PaymentMethod;
    loader: boolean;
  }

  public formattingExpirationDate(card: PaymentMethod): string {
    const month = card.exp_month >= 10 ? String(card.exp_month) : `0${card.exp_month}`;
    const year = String(card.exp_year).substr(2);

    return `${month}/${year}`;
  }
}
