import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnknownBrand = _resolveComponent("UnknownBrand")!

  return (_ctx.hasBrandCard)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$props.brand), { key: 0 }))
    : (_openBlock(), _createBlock(_component_UnknownBrand, {
        key: 1,
        class: "unknown-brand"
      }))
}