
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import {
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  Token,
  PaymentMethod,
} from '@stripe/stripe-js';

import IconsCardBrand from '@/icons/CardBrand.vue';

import requests from '@/requests';
import { CARD_BRAND, StripeCard } from '@/interface/other.interface';
import stripe from './stripe';

@Options({
  components: {
    IconsCardBrand,
  },
  computed: {
    ...mapState(['showModalLoader']),
  },
  props: {
    makeDefault: Boolean,
  },
  emits: {
    cancel: Boolean,
    submit: Array,
  },
})
export default class NewPaymentCard extends Vue {
  // TODO: add error;

  declare $refs: {
    number: HTMLElement;
    expire: HTMLElement;
    cvc: HTMLElement;
  }

  declare $props: {
    makeDefault: boolean;
  }

  declare $emits: {
    close: true;
    submit: PaymentMethod[];
  }

  public errors: string[] = [];

  public cardBrand: CARD_BRAND | null = null;

  public valueDefault = false;

  private card: StripeCard = {
    card: null,
    expire: null,
    cvc: null,
    name: '',
  }

  get validCard(): boolean {
    return (
      !!this.card?.card?.complete
      && !!this.card?.expire?.complete
      && !!this.card?.cvc?.complete
      && !!this.card.name);
  }

  public cancel(): void {
    this.$emit('cancel', true);
  }

  public save(): void {
    if (this.validCard) {
      this.changeShowModalLoader(true);

      stripe.createToken({ name: this.card.name }).then((res) => {
        if (res.token) this.createPaymentMethod(res.token);
        else this.changeShowModalLoader(false);
      });
    }
  }

  private createPaymentMethod(token: Token): void {
    stripe.createPaymentMethod({
      type: 'card',
      card: { token: token.id },
      billing_details: {
        name: token.card!.name as string,
      },
    })
      .then((res) => {
        if (res.paymentMethod) this.addNewPaymentMethod(res.paymentMethod.id);
        else this.changeShowModalLoader(false);
      });
  }

  private addNewPaymentMethod(pmId: string): void {
    requests.billing.addNewPaymentMethod(pmId)
      .then(() => {
        if (this.valueDefault || this.$props.makeDefault) this.setPMAsDefault(pmId);
        else this.getAvailableBillingMethods();
      })
      .catch((err) => {
        this.errors = err.response.data;
      })
      .finally(() => {
        this.changeShowModalLoader(false);
      });
  }

  private setPMAsDefault(pmId: string): void {
    requests.billing.setDefaultPM(pmId).then(() => {
      this.getAvailableBillingMethods();
    });
  }

  private getAvailableBillingMethods(): void {
    requests.billing.getAvailableBillingMethods()
      .then((res) => {
        this.$emit('submit', res.data);
      });
  }

  private stripeInit(): void {
    stripe.init(process.env.VUE_APP_STRIPE_PUB_KEY, this.$refs).then(() => {
      if (stripe.number && stripe.expire && stripe.cvc) {
        stripe.number.on('change', this.handleChangeNumber);
        stripe.expire.on('change', this.handleChangeExpire);
        stripe.cvc.on('change', this.handleChangeCvc);
      }
    });
  }

  private handleChangeNumber(e: StripeCardNumberElementChangeEvent): void {
    this.cardBrand = e.brand as CARD_BRAND;

    this.card.card = e;
  }

  private handleChangeExpire(e: StripeCardExpiryElementChangeEvent): void {
    this.card.expire = e;
  }

  private handleChangeCvc(e: StripeCardCvcElementChangeEvent): void {
    this.card.cvc = e;
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  mounted(): void {
    this.stripeInit();
  }

  unmounted(): void {
    stripe.destroyElements();
  }
}
