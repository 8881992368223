import { loadStripe } from '@stripe/stripe-js/pure';

import {
  StripeCardCvcElement,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeElements,
  StripeCardNumberElementOptions,
  StripeElementStyle,
  Stripe,
} from '@stripe/stripe-js';

type StripeElementRefs = {
  number: HTMLElement | string,
  cvc: HTMLElement | string,
  expire: HTMLElement | string,
}

class StripeCustomElements {
  public number: StripeCardNumberElement | null = null;

  public expire: StripeCardExpiryElement | null = null;

  public cvc: StripeCardCvcElement | null = null;

  private elements: StripeElements | null = null;

  private stripe: Stripe | null = null;

  private style: StripeElementStyle = {
    base: {
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      color: '#1D416C',
      '::placeholder': {
        color: '#7B98AE',
      },
    },
  }

  private cvcStyle: StripeElementStyle = {
    base: {
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      color: '#1D416C',
      '::placeholder': {
        fontFamily: 'monospace',
        lineHeight: '10px',
        fontSize: '20px',
        color: '#7B98AE',
      },
    },
  }

  public async init(key: string, refs: StripeElementRefs): Promise<void> {
    return new Promise((resolve, reject) => {
      loadStripe(key)
        .then((stripe: Stripe | null) => {
          if (stripe) {
            this.stripe = stripe;
            this.elements = this.stripe.elements();

            this.createElementCvc(refs.cvc);
            this.createElementNumber(refs.number);
            this.createElementExpire(refs.expire);

            resolve();
          }

          reject();
        })
        .catch((err) => reject(err));
    });
  }

  get createSource() { return this.stripe!.createSource; }

  get retrieveSource() { return this.stripe!.retrieveSource; }

  get paymentRequest() { return this.stripe!.paymentRequest; }

  get redirectToCheckout() { return this.stripe!.redirectToCheckout; }

  get retrievePaymentIntent() { return this.stripe!.retrievePaymentIntent; }

  get handleCardAction() { return this.stripe!.handleCardAction; }

  get createPaymentMethod() { return this.stripe!.createPaymentMethod; }

  public createToken(config: any) {
    return this.stripe!.createToken(this.elements!.getElement('cardNumber')!, config);
  }

  public destroyElements(): void {
    if (this.number && this.expire && this.cvc) {
      this.number.destroy();
      this.expire.destroy();
      this.cvc.destroy();
    }
  }

  private createElementNumber(ref: HTMLElement | string): void {
    if (this.elements) {
      const config: StripeCardNumberElementOptions = { style: this.style };

      this.number = this.elements.create('cardNumber', config);

      this.number.mount(ref);
    }
  }

  private createElementExpire(ref: HTMLElement | string): void {
    if (this.elements) {
      const config: StripeCardNumberElementOptions = { style: this.style };

      this.expire = this.elements.create('cardExpiry', config);

      this.expire.mount(ref);
    }
  }

  private createElementCvc(ref: HTMLElement | string): void {
    if (this.elements) {
      const config: StripeCardNumberElementOptions = {
        placeholder: '•••',
        style: this.cvcStyle,
      };

      this.cvc = this.elements.create('cardCvc', config);

      this.cvc.mount(ref);
    }
  }
}

export default new StripeCustomElements();
