
import { Options, Vue } from 'vue-class-component';
import { AxiosError } from 'axios';

import requests from '@/requests';

import CreditCard from '@/common/PaymentInformation/CreditCard.vue';
import ListAvailablePaymentCards from '@/common/PaymentInformation/ListAvailablePaymentCards.vue';
import NewPaymentCard from '@/common/PaymentInformation/NewPaymentCard.vue';
import { AvailableBilling, PaymentMethod } from '@/interface/payment.interface';

@Options({
  components: {
    CreditCard,
    ListAvailablePaymentCards,
    NewPaymentCard,
  },
  props: {
    title: String,
    listTitle: String,
  },
  emits: {
    selected: Object,
  },
})
export default class Purchase extends Vue {
  public billing: AvailableBilling | null = null;

  public isShowNewCard = false;

  public paymentMethod: PaymentMethod | null = null;

  public selectedPM(pm: PaymentMethod): void {
    this.paymentMethod = pm;

    this.triggerEmit();
  }

  public updateBilling(e: AvailableBilling): void {
    this.billing = e;

    this.isShowNewCard = false;

    this.changeShowModalLoader(false);
  }

  public getAvailableBillingMethods(): void {
    requests.billing.getAvailableBillingMethods()
      .then((res) => {
        this.billing = res.data;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          this.billing = [] as any;
          this.isShowNewCard = true;
        }
      })
      .finally(() => {
        this.changeShowModalLoader(false);
      });
  }

  public handleShowNewCard(): void {
    this.isShowNewCard = true;
    this.$emit('selected', null);
  }

  public closeNewCard(): void {
    this.isShowNewCard = false;

    this.triggerEmit();
  }

  get showNewCard(): boolean {
    return this.isShowNewCard || !this.billing?.payment_methods?.length;
  }

  private triggerEmit(): void {
    this.$emit('selected', this.paymentMethod);
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  created(): void {
    this.getAvailableBillingMethods();
  }
}
