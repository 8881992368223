import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "purchase__loader"
}
const _hoisted_2 = {
  key: 1,
  class: "purchase__container p-left-50 p-right-40"
}
const _hoisted_3 = { class: "purchase__list p-top-35" }
const _hoisted_4 = { class: "m-bottom-15" }
const _hoisted_5 = {
  key: 0,
  class: "m-bottom-30"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "purchase__card p-top-85 p-bottom-40" }
const _hoisted_8 = {
  key: 0,
  class: "c-secondary-dark m-bottom-15"
}
const _hoisted_9 = {
  key: 1,
  class: "m-bottom-30"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListAvailablePaymentCards = _resolveComponent("ListAvailablePaymentCards")!
  const _component_NewPaymentCard = _resolveComponent("NewPaymentCard")!
  const _component_CreditCard = _resolveComponent("CreditCard")!

  return (!_ctx.billing && !_ctx.isShowNewCard)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$props.title), 1),
          (_ctx.$props.listTitle)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.$props.listTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.billing?.payment_methods)
            ? (_openBlock(), _createBlock(_component_ListAvailablePaymentCards, {
                key: 1,
                list: _ctx.billing?.payment_methods,
                onSelected: _ctx.selectedPM,
                onDelete: _ctx.getAvailableBillingMethods
              }, null, 8, ["list", "onSelected", "onDelete"]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass(["btn mini disabled m-top-5 m-bottom-50", _ctx.showNewCard ? 'fill' : 'border']),
            disabled: _ctx.showNewCard,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowNewCard && _ctx.handleShowNewCard(...args)))
          }, " Add new card ", 10, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.isShowNewCard)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_8, "Add new card"))
            : (_openBlock(), _createElementBlock("h2", _hoisted_9, "Payment will be debited from this card")),
          (_ctx.showNewCard)
            ? (_openBlock(), _createBlock(_component_NewPaymentCard, {
                key: 2,
                makeDefault: !_ctx.billing?.payment_methods?.length,
                onCancel: _ctx.closeNewCard,
                onSubmit: _ctx.updateBilling
              }, null, 8, ["makeDefault", "onCancel", "onSubmit"]))
            : (_openBlock(), _createBlock(_component_CreditCard, {
                key: 3,
                card: _ctx.paymentMethod
              }, null, 8, ["card"]))
        ])
      ]))
}