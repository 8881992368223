
import { Options, Vue } from 'vue-class-component';
import { watchEffect, WatchStopHandle } from 'vue';

import UnknownBrand from './card-brand/Unknown.vue';

import amex from './card-brand/AmericamExpress.vue';
import diners from './card-brand/DinersClub.vue';
import discover from './card-brand/DiscoveryNetwork.vue';
import jcb from './card-brand/JCB.vue';
import mastercard from './card-brand/Mastercard.vue';
import unionpay from './card-brand/UnionPay.vue';
import visa from './card-brand/Visa.vue';

@Options({
  components: {
    amex,
    diners,
    discover,
    jcb,
    mastercard,
    unionpay,
    visa,
    UnknownBrand,
  },
  props: {
    brand: String,
  },
})
export default class IconsCardBrand extends Vue {
  declare $props: { brand: string; }

  public hasBrandCard = false;

  private watchStopHandler!: WatchStopHandle

  private checkBrand(): void {
    const components = { ...this.$options.components };

    const types = Object.keys(components).filter((v) => v !== 'UnknownBrand');

    this.hasBrandCard = types.some((v) => v === this.$props.brand);
  }

  created(): void {
    this.checkBrand();

    this.watchStopHandler = watchEffect(() => { this.checkBrand(); });
  }

  unmounted(): void {
    this.watchStopHandler();
  }
}
