
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';

import IconsCardBrand from '@/icons/CardBrand.vue';
import IconRemove from '@/icons/service/Remove.vue';

import SimpleModal from '@/common/SimpleModal.vue';

import requests from '@/requests';
import { PaymentMethod } from '@/interface/payment.interface';
import { SimpleModalButton } from '@/interface/other.interface';
import { toParserDate } from '@/utils/date';

@Options({
  components: {
    IconsCardBrand,
    IconRemove,
    SimpleModal,
  },
  props: {
    list: Array,
  },
  emits: {
    selected: Object,
    delete: Boolean,
  },
})
export default class ListAvailablePaymentCards extends Vue {
  declare $props: {
    list: PaymentMethod[];
  }

  public paymentMethod: PaymentMethod | null = null;

  public isShowModalDeletePM = false;

  public modalDeletePMButtons: SimpleModalButton[] = [
    { button: 'border', title: 'No', value: 'no' },
    { button: 'fill', title: 'Yes', value: -1 }, // value - it's ID payment job;
  ];

  private watchStopHandle!: WatchStopHandle;

  public handleActionDeleteModal(value: string | 'no'): void {
    if (value !== 'no') {
      this.changeShowModalLoader(true);

      requests.billing.deletePM(value)
        .then(() => {
          this.$emit('delete', true);
        });
    }

    this.closeDeletePMModals();
  }

  public handleShowModalDeletePM(pm: PaymentMethod): void {
    const button = this.modalDeletePMButtons[1];

    button.value = pm.stripe_id;

    this.modalDeletePMButtons.splice(1, 1, button);

    this.isShowModalDeletePM = true;
  }

  public closeDeletePMModals(): void {
    this.isShowModalDeletePM = false;
  }

  public handleSelectingPM(pm: PaymentMethod): void {
    this.paymentMethod = pm;

    this.triggerEmit();
  }

  public cardExpire(pm: PaymentMethod): boolean {
    const d = toParserDate();

    if (pm.exp_year < d.year.count || pm.exp_month < d.month.month) {
      return true;
    }

    return false;
  }

  private triggerEmit(): void {
    this.$emit('selected', this.paymentMethod);
  }

  private findDefaultCard(): void {
    const defaultCard = this.$props.list.find((v) => v.default);

    this.$props.list.sort((a) => (a.default ? -1 : 0));

    if (defaultCard) this.paymentMethod = defaultCard;

    this.triggerEmit();
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  created(): void {
    this.findDefaultCard();

    this.watchStopHandle = watch(() => this.$props.list, () => { this.findDefaultCard(); });
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
